module.exports = [{
      plugin: require('/Users/zach/Source/mocjic.com/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"mocjic","short_name":"mocjic","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"src/assets/images/avatar2.jpg"},
    },{
      plugin: require('/Users/zach/Source/mocjic.com/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/zach/Source/mocjic.com/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
